<template>
    <div class="countersign scrolling">
        <div class="choosePersons">
            <div class="font">
                <div class="line-item" v-for="(o, i) in list" :key="i">
                    <span class="sp-icon">
                        <img src="../images/person.png" alt="" />
                    </span>
                    <a-input
                        style="padding-left: 0"
                        placeholder="请输入"
                        v-model="o.name"
                        readOnly
                        class="input-dom input-dom-edit"
                    />
                    <a-button type="link" icon="form" class="edit-icon" @click="editDom" />
                    <span class="zusi" v-if="i == 0">（注：点击可修改名称）</span>
                    <a-button type="link" icon="delete" @click="delDom(i)" v-if="i > 0" />
                    <div class="left-line flex0">
                        <div class="tag-box flex-wrap">
                            <div v-for="(o1, i1) in o.val" :key="i1" class="flex0">
                                <a-tag class="tags flex0">
                                    <OpenData
                                        :type="o1.type"
                                        :openid="o1.userId || o1.id"
                                        :corpid="o1.corpId"
                                    ></OpenData>
                                    <a-button type="link" icon="close" @click="o.val.splice(i1, 1)" />
                                </a-tag>
                                <a-icon type="arrow-right" class="arrow-right" />
                            </div>
                            <div class="addPersonBtn" @click="showSelectDialog('list', i)">
                                <img src="../images/addRound.png" alt="" />
                            </div>
                            <!-- <a-button type="primary" icon="plus" ghost class="plus-bt font" @click="choosePerson('list', i)">
              </a-button> -->
                        </div>
                    </div>
                </div>

                <div class="line-item">
                    <div class="flex">
                        <span class="sp-icon">
                            <img src="../images/add.png" alt="" />
                        </span>
                        <span class="addLabel">添加节点</span>
                        <span class="addDom" @click="addDom">
                            <img src="../images/plus.png" alt="" />
                        </span>
                    </div>

                    <!-- <a-button type="primary" shape="circle" icon="plus" @click="addDom" /> -->
                    <div class="left-line flex0"></div>
                </div>
            </div>
        </div>
        <div class="footer">
            <a-button :loading="loading" block type="primary" @click="handleOk">保存节点</a-button>
        </div>
        <selectLabelPer ref="selectLabelPer" @selectTodoMsg="selectTodoMsg"></selectLabelPer>
        <selectPerson ref="selectPerson" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>
<script>
import { acceptAddNextNode } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { initWx } from '../components/minxin'
import selectLabelPer from '../components/selectLabelPer.vue'
import selectPerson from '../components/selectPerson'
export default {
    components: {
        OpenData,
        selectPerson,
        selectLabelPer,
    },
    mixins: [initWx],
    data() {
        return {
            list: [{ name: '节点', val: [] }],
            approvalId: '',
            loading: false,
            nodeId: '',
        }
    },
    created() {
        const { approvalId, nodeId } = this.$route.query
        this.nodeId = nodeId
        this.approvalId = approvalId
    },
    methods: {
        editDom() {
            const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
            dom.removeAttribute('readOnly')
            dom.classList.remove('input-dom-edit')
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
        showSelectDialog(key, index) {
            this.$refs.selectPerson.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.choosePerson(data.key, data.index)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key, index } = data
                console.log(result, key, index)
                result.forEach((ele) => {
                    if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                        this[key][index].val.push(ele)
                    }
                })
            }
        },
        addDom() {
            this.list.push({ name: '节点', val: [] })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        handleOk() {
            if (this.loading) return
            this.loading = true
            if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.warning('存在节点没有选择人员！')
                return
            }
            const documentNodeParams = this.list.map((el, index) => ({
                billId: this.$route.query.id,
                sort: index,
                name: el.name,
                approvals: el.val.map(({ corpId, userId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId: corpId,
                })),
            }))
            acceptAddNextNode({
                documentNodeParams,
                approvalId: this.approvalId,
                nodeId: this.nodeId,
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$nextTick(() => {
                            this.$router.go(-2)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = true
                })
        },
        choosePerson(list, index) {
            const val = [...this[list][index].val]
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this[list][index], 'val', [...result, ...resPart])
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>
<style lang="less" scoped>
.countersign {
    .flex {
        display: flex;
        align-items: center;
    }
    .choosePersons {
        padding: 15px 22px 22px;
        .radioContainer {
            margin-bottom: 27px;
            .radioLabel {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #1c1c1c;
            }
        }
        .line {
            height: 1px;
            border: 1px dashed #3b9afa;
            display: block;
            width: 100%;
            margin: 30px 0;
        }
        .plus-bt {
            width: 46px;
            height: 46px;
            background: #e9f2fe;
            border-radius: 4px;
            margin: 10px 0;
        }
        .left-line::before {
            content: '';
            height: 50px;
            position: relative;
            top: 0px;
            left: 13px;
            width: 2px;
            background: #cdcfd1;
        }
        .left-line-last:before {
            background: white;
        }
        .sp-icon {
            width: 26px;
            height: 26px;
            display: inline-block;
            margin-top: 1px;
            margin-bottom: 1px;
            // font-size: ;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .addLabel {
            margin-left: 6px;
            margin-right: 5px;
        }
        .addPersonBtn {
            width: 30px;
            height: 30px;
            font-size: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .addDom {
            width: 15px;
            height: 15px;
            display: inline-block;
            font-size: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .submit-bt {
            text-align: right;
            margin: 40px 0 40px;
            button {
                width: 100px;
                height: 36px;
                background: #3b9afa;
                border-radius: 4px;
                margin-right: 10px;
            }
        }
        .edit-icon {
            margin-left: 10px;
        }
        .zusi {
            font-size: 12px;
            color: #acacac;
        }
        .input-dom {
            width: 100px;
            margin-left: 6px;
        }
        .input-dom-edit {
            border: none;
        }
        .input-dom-edit:hover {
            border: none;
        }
        .input-dom-edit:focus {
            border: none;
        }
        .tags {
            height: 30px;
            background: #f2f2f3;
            border: none;
            line-height: 30px;
            margin-bottom: 1px;
            button {
                margin: 0;
            }
        }
        .flex-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .tag-box {
            margin: 10px 0 10px 30px;
        }
        .arrow-right {
            font-size: 0.6em;
            color: #aaa;
            margin-right: 10px;
        }
    }
    .footer {
        width: 340px;
        margin: 0 auto;
        padding-bottom: 24px;
    }
}
</style>
